<template>
    <div>
        <!-- 点击 Report information errors 后弹出 -->
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" custom-class="dialog_normal" :append-to-body="true" @close="closeDialog">
            <div class="detail_cont report_box">
                <div class="dialog_detail_title">Data Problems</div>

                <div class="rep_list">
                    <el-form ref="reportForm" :model="reportForm" class="report_form" disabled>
                        <div class="report_item">
                            <div v-for="(report, index) in addReportList" :key="index">
                                <div class="report_form_box">
                                    <el-form-item label="Data Problems:">
                                        <el-select v-model="report.subject" placeholder="Select" @change="subjectChange(report.subject)">
                                            <el-option :label="item" :value="item" v-for="(item, index) in subjectOptions" :key="index"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="Correct Information:">
                                        <el-input v-model="report.demand" type="textarea" class="limit_box" maxlength="500" @input="inputChange(report.demand)" show-word-limit></el-input>
                                    </el-form-item>
                                    <el-form-item label="Upload files:">
                                        <el-upload class="upload-demo" :limit="3" action="fakeaction" ref="uploadFile" v-model="report.uploadFiles" :file-list="report.attachmentlist">
                                            <el-button slot="trigger">Upload</el-button>
                                        </el-upload>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="flex_center_between_box">
                                <!-- <el-button class="btn_cancel" @click="cancelReport()">Cancel</el-button> -->
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: {
        showReport: {
            type: Boolean,
            default: false,
        },
        powerStatus: {
            type: Boolean,
            default: false,
        },
        companynameen: {
            type: String,
            default: '',
        },
        saveformone: {
            type: Array,
            // required: true
        },
    },
    data() {
        return {
            // newDialog: true,
            tabName: 'Reportmycompany',
            finDialog: false,
            reportDialog: false,
            newDialogtwo: false,
            filesList: [],

            subjectOptions: ['COMPANY SNAPSHOT', 'COMPANY PROFILE', 'MANAGEMENT', 'OWNERSHIP', 'BUSINESS OPERATIONS', 'PERFORMANCE', 'IMPORT&EXPORT'],
            disabledReport1: true,
            disabledReport2: true,
            disabledReport: true,
            reportForm: { subject: '', demand: '' },
            addReportList: [{ subject: '', demand: '', uploadFiles: [], attachmentlist: [] }],
            checked: false,
        };
    },
    computed: {
        newDialog: {
            get() {
                return this.showReport;
            },
            set(newValue) {},
        },
        companyName: {
            get() {
                return this.companynameen;
            },
            set(newValue) {},
        },
    },
    mounted() {},
    watch: {
        saveformone: {
            handler(newVal, oldVal) {
                newVal.map((item, index) => {
                    this.addReportList=[]
                    item.attachment = JSON.parse(item.attachment);
                    item.attachmentlist = [];
                });
                this.addReportList = newVal;
                let that = this;
                this.addReportList.map((item, index) => {
                    item.attachment.map(ite => {
                        that.addReportList[index].attachmentlist.push({ name: ite, url: ite });
                    });
                });

                // this.loadig=false
            },
            immediate: true,
        },
    },

    methods: {
        //关闭弹窗
        closeDialog() {
            this.$emit('closeReportDialog', !this.newDialog);
        },
        // cancel(){
        //     this.showReport=false
        //     // this.$emit('closeReportDialog', !this.newDialog)
        // }
    },
};
</script>
<style scoped>
.upload-demo /deep/ .el-button {
    width: 90px;
    height: 34px;
    padding: 8px 20px;
    background: #edf9ff;
    border-radius: 12px;
    border: 1px solid #1290c9;
    color: #1290c9;
    font-size: 14px;
}

.report_form /deep/ .el-form-item__label,
.rep_list {
    padding-bottom: 10px;
}

.report_form_box {
    padding: 10px 10px 0;
    margin-top: 42px;
    margin-bottom: 20px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.report_form /deep/ .el-input__inner {
    width: 420px;
    height: 34px;
    line-height: 34px;
}
.report_form /deep/ .el-form-item {
    margin-bottom: 20px;
}
.limit_box /deep/ .el-textarea__inner {
    height: 150px;
}
.report_item {
    margin-top: 48px;
}
@media (max-width: 821px) {
  .report_form /deep/ .el-input__inner {
    width: 300px !important;
}
}
</style>

