<template>
    <div>
        <!-- 点击 Report information errors 后弹出 -->
        <div class="deep">
            <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" @close="closeDialog" custom-class="precise_dialog" :append-to-body="true">
                <div class="detail_cont">
                    <div class="dialog_detail_title">Order Report</div>

                    <div class="rep_list">
                        <el-form ref="reportForm" :model="reportForm" class="report_form" disabled>
                            <div class="report_item">
                                <div class="report_form_box">
                                    <el-form ref="form" :model="form" class="form_box" label-position="left">
                                        <div class="fin_cont_box flex_center_between_box">
                                            <div class="fin_modu_box">
                                                <div class="fin_modu">
                                                    <el-form-item label="Company Name:">
                                                        <el-input v-model="form.companyName" disabled></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Country:">
                                                        <el-input v-model="form.country" disabled></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Co. ID:">
                                                        <el-input v-model="form.coId" disabled></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="Address:">
                                                        <el-input v-model="form.address" disabled></el-input>
                                                    </el-form-item>
                                                </div>
                                            </div>
                                        </div>
                                    </el-form>
                                    <el-form-item label="What can we help you with?(maximum 500 words)">
                                        <el-input v-model="form.demand" type="textarea" class="limit_box" maxlength="500" @input="inputChange(form.demand)" show-word-limit></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                        </el-form>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        showReport: {
            type: Boolean,
            default: false,
        },
        powerStatus: {
            type: Boolean,
            default: false,
        },
        saveformtwo: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            // newDialog: true,
            tabName: 'Reportmycompany',
            finDialog: false,
            reportDialog2: false,

            form: {
                // year: new Date().getFullYear(),
                cmpanyName: '',
                country: '',
                coId: '',
                address: '',
                demand: '',
                // coreCompetency: [],
                // businessOutlook: '',
                // uploadFiles: [],
            },
            type: '701',
            filesList: [],

            disabledReport1: true,
            disabledReport2: true,
            disabledReport: true,
            reportForm: { subject: '', demand: '' },
            addReportList: [{ subject: '', demand: '' }],
        };
    },
    watch: {
        saveformtwo: {
            handler(newVal, oldVal) {
                this.form = newVal;
                this.form.country = newVal.province;
            },
            immediate: true,
        },
    },
    computed: {
        newDialog: {
            get() {
                return this.showReport;
            },
            set(newValue) {},
        },
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
        closeDialog() {
            this.$emit('closeReportDialog', false);
        },
    },
};
</script>
<style scoped>
.deep /deep/ .dialog_normal {
    width: 787px !important;
    background: #ffffff;
    border-radius: 20px;
    margin-top: 20vh !important;
}
.tab_box /deep/ .el-tabs__header {
    margin-bottom: 10px;
}
.el-tabs--card > .el-tabs__header {
    border: none;
}
.tab_box /deep/ .el-tabs__header {
    width: 100%;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}
.tab_box /deep/ .el-tabs__header {
    padding: 11px 16px;
}
.result_tab {
    padding: 20px;
}
.tab_box /deep/ .el-tabs__nav-wrap {
    width: 100%;
    height: 28px;
    /* background: #f2f5f9; */
    border-radius: 8px;
    /* border: 1px solid #e7eaed; */
}
.tab_box /deep/ .el-tabs__nav {
    width: 100%;
    border: none;
    padding: 2px 28px;
    display: flex;
    justify-content: space-between;
}
.tab_box /deep/ .el-tabs__item {
    padding: 0 15px;
    width: 160px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #8497ab;
    border: none;
    text-align: center;
}
.tab_box /deep/ .el-tabs__item:last-child {
    padding-right: 15px !important;
}
.tab_box /deep/ .el-tabs__item.is-active {
    background: #ff7600;
    box-shadow: 0px 1px 2px 0px #c44306;
    border-radius: 4px;
    font-family: 'Arial Bold';
    color: #ffffff;
}

.tab_box /deep/.el-tabs__content {
    width: 440px;
    height: 130px;
    padding: 10px 0;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.cont_item {
    position: relative;
    width: 100%;
    height: 50px;
    padding: 12px 20px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 26px;
    /* background: #f2f5f9; */
    background-image: url(../assets/img/detail/btn_ic9.png);
    background-repeat: no-repeat;
    background-size: 8px 14px;
    background-position: 95% center;
    cursor: pointer;
}

.fin_title {
    font-family: 'Arial Bold';
    margin-bottom: 20px;
    line-height: 24px;
    font-size: 20px;
    color: #022955;
}
.form_box /deep/ .el-input__inner {
    width: 162px;
    height: 34px;
    line-height: 34px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.fin_top_sel /deep/ .el-input__inner {
    width: 276px;
    height: 34px;
    /* background: #f2f5f9; */
    border-radius: 8px;
    border: 1px solid #e7eaed;
    font-size: 12px;
}
.fin_top_year /deep/.el-form-item__label,
.fin_top_item /deep/.el-form-item__label {
    width: auto !important;
}
.form_box /deep/.el-form-item__label {
    padding-right: 10px;
}
.fin_top_item /deep/ .el-form-item__content {
    margin-left: 174px !important;
}
.fin_top_year /deep/ .el-form-item__content {
    margin-left: 50px !important;
}
.fin_top_year {
    margin-left: 40px;
}
.form_box /deep/ .el-form-item__label {
    color: #022955 !important;
}
.form_box /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab !important;
}
.fin_cont_box {
    margin-bottom: 10px;
}
.fin_modu {
    /* width: 350px;
    height: 320px; */
    padding: 20px 10px;
    /* margin-bottom: 20px; */
    /* background: #f2f5f9; */
    border-radius: 8px;
    /* border: 1px solid #e7eaed; */
}
.fin_modu.last {
    height: 661px;
}
.form_box /deep/ .el-form-item__content {
    line-height: 34px;
    margin-left: 132px;
}
.form_box /deep/ .el-form-item__label {
    display: flex;
    align-items: center;
    height: 37px;
    line-height: 16px;
}

.form_box /deep/ .el-form-item {
    margin-bottom: 12px;
    display: inline-block;
    margin-right: 60px;
    margin-bottom: 12px;
}
.bus_box /deep/ .el-textarea__inner,
.bus_box /deep/ .el-input__inner {
    width: 928px;
    /* background: #f2f5f9; */
}
.bus_box2 /deep/ .el-form-item__content {
    display: flex;
}
.out_item /deep/ .el-input__inner,
.bus_box2 /deep/ .el-input__inner {
    width: 220px;
    height: 34px;
    /* background: #f2f5f9; */
}
.bus_box2 /deep/ .m_l .el-input__inner {
    margin-left: 16px;
}
.form_box /deep/ .out_item {
    margin-bottom: 20px;
}
.form_box /deep/ .el-input__icon {
    height: auto;
}
.upload-demo /deep/ .el-button {
    width: 90px;
    height: 34px;
    padding: 8px 20px;
    background: #edf9ff;
    border-radius: 12px;
    border: 1px solid #1290c9;
    color: #1290c9;
    font-size: 14px;
}
.check_box /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #8497ab;
}
.report_form /deep/ .el-form-item__label,
.check_box /deep/ .el-checkbox__label {
    font-size: 14px;
    color: #022955;
}
.check_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.check_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #1290c9;
    border-color: #1290c9;
}
.check_box /deep/ .el-checkbox__inner::after {
    height: 12px;
    left: 5px;
    top: 0px;
    width: 6px;
}
.btn_box {
    text-align: right;
    margin-top: 24px;
    margin-bottom: 16px;
}
.btn_box button + button {
    margin-left: 40px;
}
.rep_list {
    padding-bottom: 10px;
}

.add_btn {
    background: #1290c9;
    background-image: url(../assets/img/detail/add_ic.png);
}

.del_btn {
    background: #f56b6b;
    background-image: url(../assets/img/detail/del_ic.png);
}
.del_btn,
.add_btn {
    float: right;
    width: 26px;
    height: 26px;
    margin-top: -32px;
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
    border-radius: 50%;
}
.report_form_box {
    padding: 10px 10px 0;
    margin-top: 42px;
    margin-bottom: 20px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.report_form /deep/ .el-input__inner {
    width: 420px;
    height: 34px;
    line-height: 34px;
}
.report_form /deep/ .el-form-item {
    margin-bottom: 20px;
}
.limit_box /deep/ .el-textarea__inner {
    height: 150px;
}
.report_item {
    margin-top: 48px;
}
@media (max-width: 821px) {
  .report_form /deep/ .el-input__inner {
    width: 300px !important;
}
.form_box /deep/ .el-form-item__content {
    margin-left: 0px !important;
}
.report_form /deep/ .el-form-item__label {
    width: 100% !important;
}
}

</style>

