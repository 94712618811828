<template>
    <div>
        <div class="das_top_cont">
            <div class="history_box">
                <div class="title flex_center_between_box phoen_center_flex_warp">
                    <div class="title_txt flex_center_start_box">
                        <img src="../../assets/img/account/mydatabase.png" />
                        My Database
                    </div>
                </div>
                <div class="das_top_cont">
                    <ul class="das_top_list flex_center_between_box phoen_center_flex_warp" style="margin-bottom: 30px">
                        <li class="das_top_item">
                            <div class="item_img color1">
                                <img src="../../assets/img/addcreate/iconsousuo.png" />
                            </div>

                            <div class="item_group">
                                <div class="item_txt">{{ searched }}</div>
                                <label class="item_lab">Total company searched</label>
                            </div>
                        </li>
                        <li class="das_top_item">
                            <div class="item_img color2">
                                <img src="../../assets/img/head_switch_company_ic.png" />
                            </div>

                            <div class="item_group">
                                <div class="item_txt">{{ collected }}</div>
                                <label class="item_lab">Total company online data collected</label>
                            </div>
                        </li>
                        <li class="das_top_item">
                            <div class="item_img color3">
                                <img src="../../assets/img/server-update.png" />
                            </div>
                            <div class="item_group">
                                <div class="item_txt">{{ updated }}</div>
                                <label class="item_lab">Total company data updated</label>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="c_d_buttom flex_box" style="margin: 20px 0px">
                    <button class="btn_blue btn_blu flex_center" @click="ResetHistory()">
                        <img src="../../assets/img/account/update.png" />
                        Reset
                    </button>
                    <button class="btn_blue flex_center" @click="searchHistory">
                        <img src="../../assets/img/detail/btn_ic5.png" />
                        Search
                    </button>
                </div>
                <!-- <div class="title">
                    <p>Total company searched: {{ searched }}</p>
                    <p>Total company online data collected: {{ collected }}</p>
                    <p>Total company data updated: {{ updated }}</p>
                </div> -->
                <div class="right_cont_box">
                    <div class="his_sel_box" style="display: flex">
                        <div class="sel_box">
                            <el-autocomplete class="inline-input" clearable v-model="CategoryVal" :fetch-suggestions="querySearch" placeholder="Company Name" @select="handleSelect"></el-autocomplete>
                        </div>
                        <div class="sel_box computer_right_cont_box" v-if="tabActiveName != 'Viewed'">
                            <el-select v-model="IndicatorsVal" placeholder="Type">
                                <el-option v-for="(item, index) in type" :key="index" :label="item.type == '1' ? 'Online data' : item.type == '2' ? 'Offline data' : item.type" :value="item.type"></el-option>
                            </el-select>
                        </div>
                        <div class="sel_box computer_right_cont_box" v-if="tabActiveName != 'Viewed'">
                            <el-select v-model="CountryVal" placeholder="Status" collapse-tags>
                                <el-option v-for="(item, index) in status" :key="index" :label="item.label" :value="item.orderStatus"></el-option>
                            </el-select>
                        </div>
                        <div class="sel_box computer_right_cont_box">
                            <el-date-picker v-if="tabActiveName == 'Viewed'" v-model="value1" type="daterange" range-separator="" value-format="timestamp" start-placeholder="Start Date" :default-time="['00:00:00', '23:59:59']" end-placeholder="End Date"></el-date-picker>
                            <el-date-picker v-else :default-time="['00:00:00', '23:59:59']" v-model="value1" type="daterange" range-separator="" value-format="yyyy-MM-dd" start-placeholder="Start Date" end-placeholder="End Date"></el-date-picker>
                        </div>
                    </div>

                    <div class="top_tab">
                        <el-tabs v-model="tabActiveName" type="card" class="tab_box" @tab-click="handleTabClick">
                            <el-tab-pane label="Companies viewed" name="Viewed" class="result_tab">
                                <div class="table_box">
                                    <el-table ref="UsageList" class="computer_right_cont_box" :data="UsageList" border style="width: 100%">
                                        <!-- 空数据状态的插槽 -->
                                        <template slot="empty">No Data</template>
                                        <el-table-column label="No." type="index" align="center" show-overflow-tooltip :resizable="false" />
                                        <el-table-column label="Date" width="200" prop="dateTime" align="left" show-overflow-tooltip :resizable="false" />
                                        <el-table-column label="Company name" prop="companyName" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                            <template slot-scope="scope">
                                                <!-- <router-link :to="{ path: '/company/companysnapshot', query: { id3a: scope.row.companyAaaid, companyCountry: scope.row.countryCode} }">{{ scope.row.companyName }}</router-link> -->
                                                <span @click="setid(scope.row)">{{ scope.row.companyName }}</span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="User name" width="200" prop="userName" align="left" show-overflow-tooltip :resizable="false" />
                                        <!-- <el-table-column label="Type" width="140" prop="type" align="left" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.type == '1'">Online data</span>
                                                <span v-else>Offline data</span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="Status" width="120" prop="orderStatus" align="left" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.orderStatus == '4'">Under production</span>
                                                <span v-else>Completed</span>
                                            </template>
                                        </el-table-column> -->
                                    </el-table>

                                    <ul class="phone_table_box phone_page_bg">
                                        <li class="phone_table_box_item" v-for="(item, index) in UsageList" :key="index">
                                            <div class="phone_table_box_item_right">
                                                <h1 class="tbl_company line_1 flex_center_between_boxphone" style="display: flex; justify-content: space-between; align-items: center">
                                                    <div class="tbl_company line_1" style="max-width: 255px" @click="setid(item)">{{ item.companyName }}</div>
                                                </h1>

                                                <h2 class="line_1">
                                                    <div class="line_1">
                                                        <span>User name:{{ item.userName }}</span>
                                                    </div>
                                                </h2>

                                                <h2 class="line_1">
                                                    <span>
                                                        Date:
                                                        {{ item.dateTime }}
                                                    </span>
                                                </h2>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="page_i_box">
                                        <Page :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                                        <div class="search_rsult_txt">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Reports downloaded" name="Downloaded" class="result_tab">
                                <div class="table_box">
                                    <el-table ref="UsageList" class="computer_right_cont_box" :data="UsageList" border style="width: 100%">
                                        <!-- 空数据状态的插槽 -->
                                        <template slot="empty">No Data</template>
                                        <el-table-column label="No." type="index" align="center" show-overflow-tooltip :resizable="false" />
                                        <el-table-column label="Date" width="200" prop="createTime" align="left" show-overflow-tooltip :resizable="false" />
                                        <el-table-column label="Company name" prop="companyName" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                            <template slot-scope="scope">
                                                <!-- <router-link :to="{ path: '/company/companysnapshot', query: { id3a: scope.row.companyAaaid, companyCountry: scope.row.countryCode, companyNameEn: scope.row.companyName } }">{{ scope.row.companyName }}</router-link> -->
                                                <span @click="setid(scope.row)">{{ scope.row.companyName }}</span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="User name" width="200" prop="customerName" align="left" show-overflow-tooltip :resizable="false" />
                                        <el-table-column label="Type" prop="type" width="140" align="left" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.type == '1'">Online data</span>
                                                <span v-else>Offline data</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Status" width="120" prop="orderStatus" align="left" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.orderStatus == '1'">Unpaid</span>
                                                <span v-else-if="scope.row.orderStatus == '4'">Processing</span>
                                                <span v-else-if="scope.row.orderStatus == '8'">Order Completed</span>
                                                <span v-else-if="scope.row.orderStatus == '0'">Order Canceled</span>
                                                <span v-else>Order Canceled</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Download" width="100" align="center" show-overflow-tooltip :resizable="false" class-name="company_link">
                                            <template slot-scope="scope">
                                                <div class="flex_center">
                                                    <el-tooltip class="tbl_opr_tip" :visible-arrow="false" effect="light" content="download" placement="top">
                                                        <div class="oper_down" @click="hanldeDown(scope.row)" v-if="scope.row.orderStatus == '8'">
                                                            <img src="../../assets/img/account/download.png" />
                                                        </div>
                                                        <div class="oper_down disabled" v-else>
                                                            <img src="../../assets/img/account/download.png" />
                                                        </div>
                                                    </el-tooltip>
                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table>

                                    <ul class="phone_table_box phone_page_bg">
                                        <li class="phone_table_box_item" v-for="(item, index) in UsageList" :key="index">
                                            <div class="phone_table_box_item_right">
                                                <h1 class="tbl_company line_1 flex_center_between_boxphone" style="display: flex; justify-content: space-between; align-items: center">
                                                    <div class="tbl_company line_1" style="max-width: 255px" @click="setid(item)">{{ item.companyName }}</div>

                                                    <div class="phpne oper_down" @click="hanldeDown(item)" v-if="item.orderStatus == '8'">
                                                        <img src="../../assets/img/account/download.png" />
                                                    </div>
                                                    <div class="oper_down disabled" v-else @click="addToList(item)">
                                                        <img src="../../assets/img/account/download.png" />
                                                    </div>
                                                </h1>

                                                <h2 class="line_1">
                                                    <div class="line_1">
                                                        <span>User name:{{ item.customerName }}</span>
                                                    </div>
                                                </h2>
                                                <h2 class="line_1" @click="gotourl(item.web_site)">
                                                    Status:
                                                    <i style="text-decoration: underline; color: #1290c9; cursor: pointer">
                                                        <span v-if="item.orderStatus == '1'">Unpaid</span>
                                                        <span v-else-if="item.orderStatus == '4'">Processing</span>
                                                        <span v-else-if="item.orderStatus == '8'">Order Completed</span>
                                                        <span v-else-if="item.orderStatus == '0'">Order Canceled</span>
                                                        <span v-else>Order Canceled</span>
                                                    </i>
                                                </h2>
                                                <h2 class="line_1">
                                                    <span>
                                                        Type:
                                                        <span v-if="item.type == '1'">Online data</span>
                                                        <span v-else>Offline data</span>
                                                    </span>
                                                    <span style="float: right">{{ item.createTime }}</span>
                                                </h2>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="page_i_box">
                                        <Page :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                                        <div class="search_rsult_txt">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                                    </div>
                                </div>
                            </el-tab-pane>

                            <el-tab-pane label="Updates ordered" name="Updated" class="result_tab">
                                <div class="table_box">
                                    <el-table ref="UsageList" class="computer_right_cont_box" :data="UsageList" border style="width: 100%">
                                        <!-- 空数据状态的插槽 -->
                                        <!-- <div class="nodate">You have not downloaded the offline report, please go to the relevant page to downloaded</div> -->
                                        <template slot="empty">No Data</template>
                                        <el-table-column label="No." type="index" align="center" show-overflow-tooltip :resizable="false" />
                                        <el-table-column label="Date" width="200" prop="createTime" align="left" show-overflow-tooltip :resizable="false" />
                                        <el-table-column label="Company name" prop="companyName" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                            <template slot-scope="scope">
                                                <!-- <router-link :to="{ path: '/company/companysnapshot', query: { id3a: scope.row.companyAaaid, companyCountry: scope.row.countryCode, companyNameEn: scope.row.companyName } }">{{ scope.row.companyName }}</router-link> -->
                                                <span @click="setid(scope.row)">{{ scope.row.companyName }}</span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="User name" width="200" prop="customerName" align="left" show-overflow-tooltip :resizable="false" />
                                        <el-table-column label="Type" width="120" prop="type" align="left" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.type == '1'">Online data</span>
                                                <span v-else>Offline data</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Status" width="140" prop="orderStatus" align="left" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.orderStatus == '1'">Unpaid</span>
                                                <span v-else-if="scope.row.orderStatus == '4'">Processing</span>
                                                <span v-else-if="scope.row.orderStatus == '8'">Order Completed</span>
                                                <span v-else-if="scope.row.orderStatus == '0'">Order Canceled</span>
                                                <span v-else>Order Canceled</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Download" width="100" align="center" :resizable="false" class-name="company_link">
                                            <template slot-scope="scope">
                                                <div class="flex_center">
                                                    <el-tooltip class="tbl_opr_tip" :visible-arrow="false" effect="light" content="download" placement="top">
                                                        <div class="oper_down" @click="hanldeDown(scope.row)" v-if="scope.row.orderStatus == '8'">
                                                            <img src="../../assets/img/account/download.png" />
                                                            <span v-if="scope.row.isView == false && scope.row?.filePath?.length > 0" class="baioji"></span>
                                                            <span v-else class="baioji3"></span>
                                                        </div>
                                                        <div class="oper_down disabled" v-else>
                                                            <img src="../../assets/img/account/download.png" />
                                                            <span class="baioji2"></span>
                                                        </div>
                                                    </el-tooltip>
                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table>

                                    <ul class="phone_table_box phone_page_bg">
                                        <li class="phone_table_box_item" v-for="(item, index) in UsageList" :key="index">
                                            <div class="phone_table_box_item_right">
                                                <h1 class="tbl_company line_1 flex_center_between_boxphone" style="display: flex; justify-content: space-between; align-items: center">
                                                    <div class="tbl_company line_1" style="max-width: 255px" @click="setid(item)">{{ item.companyName }}</div>

                                                    <div class="phpne oper_down" @click="hanldeDown(item)" v-if="item.orderStatus == '8'">
                                                        <img src="../../assets/img/account/download.png" />
                                                        <span v-if="item.isView == false && item?.filePath?.length > 0" class="baioji"></span>
                                                        <span v-else class="baioji3"></span>
                                                    </div>
                                                    <div class="oper_down disabled" v-else>
                                                        <img src="../../assets/img/account/download.png" />
                                                        <span class="baioji2"></span>
                                                    </div>
                                                </h1>

                                                <h2 class="line_1">
                                                    <div class="line_1">
                                                        <span>User name:{{ item.customerName }}</span>
                                                    </div>
                                                </h2>
                                                <h2 class="line_1" @click="gotourl(item.web_site)">
                                                    Status:
                                                    <i>
                                                        <span v-if="item.orderStatus == '1'">Unpaid</span>
                                                        <span v-else-if="item.orderStatus == '4'">Processing</span>
                                                        <span v-else-if="item.orderStatus == '8'">Order Completed</span>
                                                        <span v-else-if="item.orderStatus == '0'">Order Canceled</span>
                                                        <span v-else>Order Canceled</span>
                                                    </i>
                                                </h2>
                                                <h2 class="line_1">
                                                    <span>
                                                        Type:
                                                        <span v-if="item.type == '1'">Online data</span>
                                                        <span v-else>Offline data</span>
                                                    </span>
                                                    <span style="float: right">{{ item.createTime }}</span>
                                                </h2>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="page_i_box">
                                        <Page :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                                        <div class="search_rsult_txt">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Service ordered" name="myrss" class="result_tab">
                                <div class="table_box">
                                    <el-table ref="rsslist" class="computer_right_cont_box"  :data="rsslist" border style="width: 100%">
                                        <!-- 空数据状态的插槽 -->
                                        <template slot="empty"><div class="nodate">No Data</div></template>
                                        <el-table-column label="No." type="index" align="center" show-overflow-tooltip :resizable="false" />
                                        <el-table-column label="Date" width="200" prop="createTime" align="left" show-overflow-tooltip :resizable="false" />
                                        <el-table-column label="Company name/Service name" prop="companyName" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                            <template slot-scope="scope">
                                                <!-- <router-link :to="{ path: '/company/companysnapshot', query: { id3a: scope.row.companyAaaid, companyCountry: scope.row.countryCode, companyNameEn: scope.row.companyName } }">{{ scope.row.companyName }}</router-link> -->
                                                <span v-if="scope.row.type != '9'" @click="setid(scope.row)">{{ scope.row.companyName }}</span>
                                                <span v-else>Find Business Partners</span>
                                            </template>
                                        </el-table-column>

                                        <el-table-column label="User name" width="200" prop="loginName" align="left" show-overflow-tooltip :resizable="false" />
                                        <el-table-column label="Type" width="120" prop="type" align="left" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.type == '1'">Online Data</span>
                                                <span v-if="scope.row.type == '6'">Report Error</span>
                                                <span v-if="scope.row.type == '601'">Report Error</span>
                                                <span v-if="scope.row.type == '7'">Update Data</span>
                                                <span v-if="scope.row.type == '701'">Update Data</span>
                                                <span v-if="scope.row.type == '9'">Precise Push</span>
                                                <span v-if="scope.row.type == '8'">Data Feedback</span>
                                                <!-- <span v-else>Offline data</span> -->
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Status" width="140" prop="orderStatus" align="left" show-overflow-tooltip :resizable="false">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.status == '4'">Under production</span>
                                                <span v-else-if="scope.row.status == '3'">Finished</span>
                                                <span v-else-if="scope.row.status == '1'">Accepting</span>
                                                <span v-else>Completed</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Osperate" width="100" align="center" :resizable="false" class-name="company_link">
                                            <template slot-scope="scope">
                                                <div class="flex_center">
                                                    <div class="oper_down" @click="viewcheck(scope.row)">
                                                        <img src="../../assets/img/account/detail_ic.png" />

                                                        <span class=""></span>
                                                    </div>
                                                    <!-- <div class="oper_down disabled" v-else>
                                                        <img src="../../assets/img/account/detail_ic.png" />
                                                        <span class=""></span>
                                                    </div> -->
                                                    <!-- </el-tooltip> -->
                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <ul class="phone_table_box phone_page_bg">
                                        <li class="phone_table_box_item" v-for="(item, index) in rsslist" :key="index">
                                            <div class="phone_table_box_item_right">
                                                <h1 class="tbl_company line_1 flex_center_between_boxphone" style="display: flex; justify-content: space-between; align-items: center">
                                                    <div class="tbl_company line_1" style="max-width: 255px" >  <span v-if="item.type != '9'" @click="setid(item)">{{ item.companyName }}</span>
                                                <span v-else>Find Business Partners</span></div>

                                                    <div class="phpne oper_down" @click="viewcheck(item)">
                                                        <img src="../../assets/img/account/detail_ic.png" />
                                                        <span class=""></span>
                                                    </div>
                                                </h1>

                                                <h2 class="line_1">
                                                    <div class="line_1">
                                                        <span>User name:{{ item.loginName }}</span>
                                                    </div>
                                                </h2>
                                                <h2 class="line_1" @click="gotourl(item.web_site)">
                                                    Status:
                                                    <i>
                                                        <span v-if="item.status == '4'">Under production</span>
                                                        <span v-else-if="item.status == '3'">Finished</span>
                                                        <span v-else-if="item.status == '1'">Accepting</span>
                                                        <span v-else>Completed</span>
                                                    </i>
                                                </h2>
                                                <h2 class="line_1">
                                                    <span>
                                                        Type:
                                                        <span v-if="item?.type == '1'">Online Data</span>
                                                        <span v-if="item?.type == '6'">Report Error</span>
                                                        <span v-if="item?.type == '601'">Report Error</span>
                                                        <span v-if="item?.type == '7'">Update Data</span>
                                                        <span v-if="item?.type == '701'">Update Data</span>
                                                        <span v-if="item?.type == '9'">Precise Push</span>
                                                        <span v-if="item?.type == '8'">Data Feedback</span>
                                                    </span>
                                                    <span style="float: right">{{ item.createTime }}</span>
                                                </h2>
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="page_i_box">
                                        <Page :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                                        <div class="search_rsult_txt">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                                    </div>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </div>
            </div>
        </div>

        <!-- List of login duration and times 弹框 -->
        <el-dialog :visible.sync="timesDetailDialog" center :close-on-click-modal="false" custom-class="dialog_normal" :append-to-body="true">
            <div class="detail_cont">
                <div class="popTitle">{{ detailObj.titleDate }}</div>
                <div class="detail_pop_list">
                    <div class="detail_pop_title_box flex_center">
                        <div class="detail_pop_title">Date</div>
                        <div class="detail_pop_title">Login duration</div>
                        <div class="detail_pop_title">Number of logins</div>
                    </div>
                    <div class="detail_pop_item flex_center" v-for="(item, index) in detailObj.results" :key="index">
                        <div class="detail_pop_txt">{{ item.Date }}</div>
                        <div class="detail_pop_txt">{{ item.LoginDuration }}</div>
                        <div class="detail_pop_txt">{{ item.NumberOfLogins }}</div>
                    </div>
                </div>
                <div class="txt_right">
                    <button class="btn_export">Yes</button>
                </div>
            </div>
        </el-dialog>
        <!-- 精准推送扣点 -->
        <el-dialog :visible.sync="serivce" :close-on-click-modal="false" custom-class=" precise_dialog" :append-to-body="true">
            <div class="block-con-body-text">
                <div class="dialog_detail_title">More Suggestions</div>
                <!-- <div class="koudian">
        
                    <div class="koudian-cont">
                        <h3>Consumption points&nbsp;:&nbsp;{{ consume }}</h3>
                    </div>
                </div> -->
                <h3 class="koudian">
                    <span class="koudian-title">Products / services:</span>
                    <span style="width: 500px; color: #c0c4cc">{{ searchProName }}</span>
                </h3>

                <h3 class="koudian">
                    <span class="koudian-title">You are:</span>
                    <ul>
                        <!-- <li style="margin-bottom: 5px;width: 500px;color: #C0C4CC;">{{ enName }}</li> -->
                        <li v-for="(item, index) in checkList" :key="index" style="color: #c0c4cc">{{ item }}</li>
                    </ul>
                    <!-- <span style="width: 500px;color: #C0C4CC;">{{ checkList.join(',') }}</span> -->
                </h3>

                <h3 class="koudian">
                    <span class="koudian-title">Countries interested:</span>
                    <span v-for="(item, index) in countryCode" :key="index" style="margin-right: 8px; color: #c0c4cc">{{ getCountry(item) }}</span>
                </h3>

                <h3 class="koudian">
                    <span class="koudian-title">Companies interested:</span>
                    <ul>
                        <li style="margin-bottom: 5px; width: 500px; color: #c0c4cc">{{ enName }}</li>
                        <!-- <li style="width: 500px;color: #C0C4CC;">{{ CompetitiveenName }}</li> -->
                    </ul>
                </h3>
                <h3 class="koudian">
                    <span class="koudian-title">Competitive companies:</span>
                    <ul>
                        <!-- <li style="margin-bottom: 5px;width: 500px;color: #C0C4CC;">{{ enName }}</li> -->
                        <li style="width: 500px; color: #c0c4cc">{{ CompetitiveenName }}</li>
                    </ul>
                </h3>

                <h3 class="koudian">
                    <span class="koudian-title">Look for:</span>
                    <ul>
                        <!-- <li style="margin-bottom: 5px;width: 500px;color: #C0C4CC;">{{ enName }}</li> -->
                        <li v-for="(item, index) in checkList1" :key="index" style="color: #c0c4cc">{{ item }}</li>
                    </ul>
                    <!-- <span style="width:500px ;color: #C0C4CC;">{{ checkList1.join(',') }}</span> -->
                </h3>
            </div>

            <div class="block-con-body-text">
                <div class="detail_cont-one">
                    <h1>What types of info do you like to include in your report?</h1>
                    <div>
                        <el-checkbox-group v-model="checkedCities" disabled>
                            <el-checkbox label="Main business activities">Main business activities</el-checkbox>
                            <el-checkbox label="Business scale">Business scale</el-checkbox>
                            <el-checkbox label="Contact info">Contact info</el-checkbox>
                            <el-checkbox label="Potential vendors">Potential vendors</el-checkbox>
                            <el-checkbox label="Industry info">Industry info</el-checkbox>
                            <el-checkbox label="Trade records">Trade records</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <div class="detail_cont-one">
                    <h1>How often do you want us to send you this report?</h1>
                    <div>
                        <el-radio-group v-model="radio" @input="radiochang()" disabled>
                            <el-radio label="One time">One time</el-radio>
                            <el-radio label="Monthly">Monthly</el-radio>
                            <el-radio label="Quarterly">Quarterly</el-radio>
                        </el-radio-group>
                    </div>
                </div>

                <div class="detail_cont-one" v-if="radio != 'One time' || radio == 'Monthly' || radio == 'Quarterly'">
                    <h1>How long do you want to keep this service?</h1>
                    <div>
                        <el-radio-group v-model="radio2" @input="radiochang()" disabled>
                            <el-radio label="One time">One time</el-radio>
                            <el-radio label="3 months">3 months</el-radio>
                            <el-radio label="6 months">6 months</el-radio>
                        </el-radio-group>
                    </div>
                </div>
                <div class="detail_cont-one" style="margin-bottom: 0px">
                    <h1>How many companies do you want us to recommend?</h1>
                    <div>
                        {{ inputnum }}
                    </div>
                </div>
                <div class="detail_cont-one">
                    <h1>Choose how to deliver you the report?</h1>
                    <div class="form-email">
                        <el-form :model="formData" ref="form">
                            <el-form-item style="display: flex" label="Email:" prop="email" disabled>
                                <el-input style="width: 350px" v-model="formData.email" disabled></el-input>
                            </el-form-item>
                            <!-- <el-button @click="submitForm">Submit</el-button> -->
                        </el-form>
                    </div>
                </div>
            </div>

            <div class="flex_center_between_box phoen_center_flex_warp" style="justify-content: center; margin-bottom: 20px">
                <el-button class="btn_export" @click="serivce = false">OK</el-button>
            </div>
        </el-dialog>
        <!-- 报错更新弹框 -->
        <Reporterrorundare :showReport="showReportIndex" @closeReportDialog="closeDialog($event)" :powerStatus="true" :saveform="form"></Reporterrorundare>
        <!-- 报错 -->
        <Reporterror :showReport="showReportIndextwo" @closeReportDialog="closeDialogtwo($event)" :powerStatus="true" :saveformone="formerror"></Reporterror>
        <!-- 高级检索意见 -->
        <Deepassment :showReport="showReportIndextree" @closeReportDialog="closeDialog3($event)" :powerStatus="true" :saveformtwo="formerrortwo"></Deepassment>
    </div>
</template>
<script>
import Page from '@/components/page';
import { getOrdersList, getPdfAddress, getOrderview, getPortfolio, creditServiceList, getTypeAndStatusList, getMyDataBaseCount } from '../../api/userApi';
import { mixins1 } from '@/mixins/index';
import Reporterrorundare from '@/components/mydatabasereport';
import Reporterror from '@/components/mydatabseerror';
import Deepassment from '@/components/issdeepassment.vue';
export default {
    components: { Page, Reporterror, Reporterrorundare, Deepassment },
    mixins: [mixins1],
    data() {
        return {
            value1: '',
            CategoryVal: '',
            IndicatorsVal: '',
            CountryVal: '',
            YearVal: '',
            IndicatorsOptions: [],
            status: [
                { orderStatus: '4', id: 3701 },
                { orderStatus: '8', id: 3700 },
                { orderStatus: '1', id: 3701 },
                { orderStatus: '0', id: 3700 },
            ],
            type: [
                { type: '1', id: 3702 },
                { type: '2', id: 3703 },
            ],
            timesDetailDialog: false, //List of login duration and times 弹框
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            tabActiveName: 'Viewed',

            UsageList: [{}],

            detailObj: {
                titleDate: '2022-11',
                results: [
                    { Date: '2022/11/14', LoginDuration: '10h', NumberOfLogins: '1' },
                    { Date: '2022/11/14', LoginDuration: '12h', NumberOfLogins: '1' },
                    { Date: '2022/11/14', LoginDuration: '10h', NumberOfLogins: '1' },
                ],
            },
            rsslist: [],
            serivce: false,
            searchProName: '',
            checkList: [],
            countryCode: [],
            enName: '',
            CompetitiveenName: '',
            checkList1: [],
            radio: '',
            num: '',
            formData: {
                saveitem: '',
                email: '',
            },
            radio2: '',
            Calculation: '',
            checkedCities: '',
            showReportIndex: false,
            showReportIndextwo: false,
            showReportIndextree: false,
            form: {},
            formerror: [],
            formerrortwo: {},
            searched: '',
            collected: '',
            updated: '',
            inputnum: '',
            window:window
        };
    },
    beforeDestroy() {
        this.$EventBus.$off('showbell');
    },
    destroyed() {
        localStorage.removeItem('show');
    },
    mounted() {
        this.getlingdang();
    },

    methods: {
        closeDialog3(value) {
            this.showReportIndextree = value;
        },
        closeDialog(value) {
            this.showReportIndex = value;
            // this.showReportIndextwo=value
        },
        closeDialogtwo(value) {
            // this.showReportIndex = value;
            this.showReportIndextwo = value;
        },
        // 查看报告
        viewcheck(view) {
            if (view.type == '8') {
                this.formerrortwo = view;
                this.showReportIndextree = true;
                this.showReportIndex = false;
                this.showReportIndextwo = false;
            } else if (view.type == '9') {
                let form = [];
                form = JSON.parse(view.demand);
                this.showReportIndex = false;
                this.searchProNamecode = form.productCode;

                this.searchProName = form.productName;
                this.enName = form.expectedCompany;
                this.checkList = form.productChannel?.split(',');

                this.countryCode = form.targetCountry.split('@').map(code => code.trim());
                // this.textarea1=tag.productNameDesc
                // this.textarea2=tag.targetCountryDesc
                // this.textarea3=tag.expectedCompanyDesc
                // this.textarea4=tag.productNameDesc

                this.CompetitiveenName = form.competitiveEnterprise;
                this.checkList1 = form.partner?.split(',');

                this.CompetitiveenName = form.competitiveEnterprise;
                this.radio = form.radio;
                this.radio2 = form.radio2;
                this.checkedCities = form.checkedCities == undefined ? [] : form.checkedCities?.split(',');
                this.formData.email = form.email;
                this.inputnum = form.inputnum;
                this.serivce = true;
            } else if (view.type == '701' || view.type == '7') {
                let form = [];
                form = JSON.parse(view.demand);
                this.showReportIndex = true;
                this.form = form;
                this.showReportIndextree = false;
                this.showReportIndextwo = false;
            } else if (view.type == '601' || view.type == '6') {
                let form = [];
                form = JSON.parse(view.demand);
                this.formerror = form;
                this.showReportIndextwo = true;
                this.showReportIndex = false;

                this.showReportIndextree = false;
            }
        },
        // 获取铃铛状态显示 updatated orderred
        getlingdang() {
            if (localStorage.getItem('show')) {
                this.tabActiveName = localStorage.getItem('show');
            }
            getMyDataBaseCount().then(res => {
                if (res.code) {
                    this.updated = res.data.companyUpdatedTotal;
                    this.collected = res.data.companyOnlineTotal;
                    this.searched = res.data.companySearchedTotal;
                }
            });
            this.tab();
        },

        handleSelect(item) {
            // console.log(this.CategoryVal);
        },
        querySearch(queryString, cb) {
            var IndicatorsOptions = this.IndicatorsOptions;

            IndicatorsOptions.map(item => {
                item.value = item.companyName == undefined ? '' : item.companyName;
            });
            var results = queryString ? IndicatorsOptions.filter(this.createFilter(queryString)) : IndicatorsOptions;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            // console.log(queryString);
            return IndicatorsOptions => {
                return IndicatorsOptions.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            };
        },
        async getTypeAndStatus() {
            let res = await getTypeAndStatusList();

            if (res.code) {
                let type = [];
                res.data.typeList.map((item, index) => {
                    type.push({ type: item });
                });

                let status = [];
                res.data.statusList.map((item, index) => {
                    status.push({ orderStatus: item });
                });
                this.type = type;
                this.status = status;
            }
        },
        async getportfoillist() {
            const params = {
                page: this.page.pageNo,
                limit: this.page.pageSize,
                companyName: this.CategoryVal,
                startTimed: this.value1[0],
                endTimed: this.value1[1],
            };
            let res = await getPortfolio(params);
            if (res.code) {
                this.UsageList = res.data.rows;
                this.page.total = res.data.total;
                this.IndicatorsOptions = res.data.rows;
            } else {
                this.UsageList = [];
            }
        },
        async getOrderdata(type) {
            let code = JSON.parse(localStorage.getItem('userInfo')).userInfo.userCode;

            const param = {
                page: this.page.pageNo,
                limit: this.page.pageSize,
                customerCode: code,
                type: this.IndicatorsVal == '' ? type : this.IndicatorsVal,
                companyName: this.CategoryVal,
                startTime: this.value1[0],
                endTime: this.value1[1],
                orderStatus: this.CountryVal,
            };
            let res = await getOrdersList(param);
            if (res.code) {
                this.UsageList = res.data.rows;
                this.page.total = res.data.total;
                this.IndicatorsOptions = res.data.rows;
                // console.log(this.UsageList);
            } else {
                this.UsageList = [];
            }
        },
        getcreidtsevere() {
            let params = {
                page: this.page.pageNo,
                limit: this.page.pageSize,
                type: this.IndicatorsVal,
                startTime: this.value1[0],
                endTime: this.value1[1],
                orderStatus: this.CountryVal,

                companyName: this.CategoryVal,
                // type: this.IndicatorsVal == '' ? type : this.IndicatorsVal,
            };
            creditServiceList(params).then(res => {
                if (res.code && res.data.rows != null) {
                    this.rsslist = res.data.rows;
                    this.rsslist.map(item => {
                        const [year, month, day, hours, minutes, seconds] = item.createTime;
                        const formattedMonth = String(month).padStart(2, '0');
                        const formattedDay = String(day).padStart(2, '0');
                        item.createTime = `${year}-${formattedMonth}-${formattedDay} ${hours}:${minutes}:${seconds}`;
                    });
                    this.page.total = res.data.total;
                    this.IndicatorsOptions = res.data.rows;
                } else {
                    this.rsslist = [];
                    this.IndicatorsOptions = [];
                }
            });
        },

        setid(row) {
            sessionStorage.setItem('company', JSON.stringify(row));
            if (this.tabActiveName == 'Viewed') {
                let routeData = this.$router.resolve({
                    path: '/company/companysnapshot?id3a=' + row.aaaId + '&companyCountry=' + row.countryCode,
                });
                window.open(routeData.href, '_blank');
            } else if (this.tabActiveName == 'myrss') {
                let routeData = this.$router.resolve({
                    path: '/company/companysnapshot?id3a=' + row.companyId + '&companyCountry=' + row.countryCode,
                });
                window.open(routeData.href, '_blank');
            } else {
                let routeData = this.$router.resolve({
                    path: '/company/companysnapshot?id3a=' + row.companyAaaid + '&companyCountry=' + row.countryCode,
                });
                window.open(routeData.href, '_blank');
            }

            // window.open(routeData.href, '_blank');
        },
        // 分页
        onPageChange(pageNo) {
            this.page.pageNo = pageNo;
            this.tab();
        },
        tab() {
            this.value1 = '';
            if (this.tabActiveName == 'Downloaded') {
                (this.status = [
                    { orderStatus: '4', id: 3700, label: 'Processing' },
                    { orderStatus: '8', id: 3700, label: 'Order Completed' },
                    { orderStatus: '0', id: 3700, label: 'Order Canceled' },
                    { orderStatus: '1', id: 3700, label: 'Unpaid' },
                ]),
                    (this.type = [
                        { type: '1', id: 3702 },
                        { type: '2', id: 3703 },
                    ]),
                    // this.IndicatorsVal = '1'
                    this.getOrderdata('1');
            }
            if (this.tabActiveName == 'Updated') {
                (this.status = [
                    { orderStatus: '4', id: 3700, label: 'Processing' },
                    { orderStatus: '8', id: 3700, label: 'Order Completed' },
                    { orderStatus: '0', id: 3700, label: 'Order Canceled' },
                    { orderStatus: '1', id: 3700, label: 'Unpaid' },
                ]),
                    (this.type = [
                        { type: '1', id: 3702 },
                        { type: '2', id: 3703 },
                    ]),
                    // this.IndicatorsVal = 2
                    this.getOrderdata('2');
            }
            if (this.tabActiveName == 'Viewed') {
                // this.IndicatorsVal = 2
                this.getportfoillist();
            }
            if (this.tabActiveName == 'myrss') {
                this.getTypeAndStatus();
                // this.IndicatorsVal = 2
                this.getcreidtsevere();
            }
        },
        currtentPageChange(pageSize) {
            this.page.pageSize = pageSize;
            this.tab();
        },
        //tab切换
        handleTabClick(tab, event) {
            this.IndicatorsVal = '';
            this.CountryVal = '';
            this.CategoryVal = '';
            (this.page.pageNo = 1), (this.page.pageSize = 10), this.tab();
        },
        //Order List
        async hanldeDown(row) {
            const orderId = row.id;

            await getPdfAddress(orderId).then(res => {
                if (res && res.code) {
                    getOrderview({ id: orderId, isView: true }).then(res => {
                        this.tab();
                    });
                    window.open(res.data, '_blank');
                       if (this.window.innerWidth < 720) {
                                window.location.href = res.data;
                            }
                } else {
                    this.$message({
                        message: res.msg,
                        center: true,
                        type: 'error',
                    });
                }
            });
        },
        //Recharge list
        hanldeApply(row) {},
        //List of login duration and times
        hanldeDetails(row) {
            this.timesDetailDialog = true;
        },
        ResetHistory() {
            // this.IndicatorsOptions=[]
            this.CategoryVal = '';
            this.IndicatorsVal = '';
            this.CountryVal = '';
        },
        searchHistory() {
            if (this.tabActiveName == 'Downloaded') {
                // this.IndicatorsVal = '1'
                this.getOrderdata('1');
            }
            if (this.tabActiveName == 'Updated') {
                // this.IndicatorsVal = 2
                this.getOrderdata('2');
            }
            if (this.tabActiveName == 'Viewed') {
                // this.IndicatorsVal = 2
                this.getportfoillist();
            }
            if (this.tabActiveName == 'myrss') {
                // this.IndicatorsVal = 2
                this.getcreidtsevere();
            }
        },
    },
};
</script>
<style scoped>
/* 日历大小 */
.el-range-editor.el-input__inner {
    width: 224px !important;
    padding: 3px 3px;
}

.el-date-editor /deep/ .el-range__icon {
    font-size: 14px;
    margin-left: -5px;
    color: #c0c4cc;
    /* float: left; */
    line-height: 32px;
    position: relative;
    left: 47%;
}

.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}

.country_box {
    margin-top: 60px;
}

.country_box .title {
    margin-bottom: 0;
}

.history_box {
    margin-top: 22px;
}

.title {
    margin-bottom: 20px;
}

.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}

.title_txt img {
    width: 20px;
    margin-right: 8px;
}

.c_d_buttom {
    justify-content: flex-end;
    /* margin-top: 20px; */
    margin-bottom: 0;
}

.btn_blue {
    width: 140px;
    height: 40px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
}

.btn_blu {
    margin-right: 20px;
}

.btn_blue img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
}
.sel_box {
    margin-right: 25px;
}
.his_sel_box .sel_box /deep/ .el-input__inner {
    border-color: #8497ab;
    border-radius: 8px;
}

.his_sel_box .sel_box /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab;
    font-weight: 500;
}

.das_top_cont {
    /* margin-top: 50px; */
}

.das_top_item {
    padding: 20px;
    border-radius: 14px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}

.das_top_item .item_img {
    width: 40px;
    height: 40px;
    line-height: 48px;
    text-align: center;
    border-radius: 50%;
}

.das_top_item .color1 {
    background: #1290c9;
}

.das_top_item .color2 {
    background: #f56b6b;
}

.das_top_item .color3 {
    background: #55b419;
}

.das_top_item .color4 {
    background: #ff7600;
}

.das_top_item .item_title {
    font-size: 18px;
    color: #022955;
    margin: 10px 0 10px;
    text-align: center;
}

.top_tab {
    margin-top: 40px;
}

.tab_box /deep/ .el-tabs__header {
    margin-bottom: 10px;
}

.el-tabs--card > .el-tabs__header {
    border: none;
}

.tab_box /deep/ .el-tabs__header,
.tab_box /deep/.el-tabs__content {
    width: 100%;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}

.tab_box /deep/ .el-tabs__header {
    padding: 11px 75px;
}

.result_tab {
    padding: 20px;
}

.tab_box /deep/ .el-tabs__nav-wrap {
    width: 690px;
    margin: auto;
    height: 34px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}

.tab_box /deep/ .el-tabs__nav {
    width: 100%;
    border: none;
    padding: 6px 12px;
    display: flex;
    justify-content: space-around;
}

.tab_box /deep/ .el-tabs__item {
    /* padding: 0 5px; */
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #8497ab;
    width: 120px;

    border: none;
    text-align: center;
}

.tab_box /deep/ .el-tabs__item:last-child {
    padding-right: 15px !important;
}

.tab_box /deep/ .el-tabs__item.is-active {
    width: 180px;
    background: #ff7600;
    box-shadow: 0px 1px 2px 0px #c44306;
    border-radius: 4px;
    font-size: 14px;
    font-family: 'Arial Bold';
    color: #ffffff;
}

.tab_box /deep/ .el-table--scrollable-x .el-table__body-wrapper {
    overflow: hidden;
}

.tab_box /deep/ .el-table__body,
.tab_box /deep/ .el-table__footer,
.tab_box /deep/ .el-table__header {
    width: auto !important;
}

.oper_down img {
    width: 18px;
    height: 16px;
}

.detail_pop_list {
    margin: 40px auto 0;
}

.detail_pop_title_box {
    border-bottom: 1px solid #e7eaed;
    padding-bottom: 10px;
}

.detail_pop_item {
    border-bottom: 1px solid #e7eaed;
    padding: 15px 0;
}

.detail_pop_txt,
.detail_pop_title {
    width: 33.3%;
    text-align: center;
    font-size: 16px;
}

.detail_pop_title {
    color: #8497ab;
}

.detail_pop_txt {
    color: #022955;
}

.txt_right {
    text-align: right;
    margin-top: 15px;
}

.oper_down img {
    width: 18px;
    height: 16px;
}
.oper_down.disabled {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray;
    opacity: 0.5;
    cursor: no-drop;
}
.baioji {
    display: inline-block;
    height: 5px;
    width: 5px;
    background-color: #c44306;
    border-radius: 50%;
    position: relative;
    top: -10px;
}
.baioji2 {
    display: inline-block;
    height: 5px;
    width: 10px;
    background-color: #ffffff;
    border-radius: 50%;
    position: relative;
    top: -10px;
}
.baioji3 {
    display: inline-block;
    height: 5px;
    width: 5px;
    background-color: #ffffff;
    border-radius: 50%;
    position: relative;
    top: -10px;
}
.nodate {
    line-height: 30px !important;
}
.dialog_detail_title {
    font-size: 20px;
    font-family: 'Arial Bold';
    color: #022955;
    line-height: 23px;

    text-align: center;
    padding-bottom: 20px;
    /* border: 1px solid; */
    border-bottom: 1px solid;
    text-align: left;
}
.koudian-title {
    font-size: 14px;
    width: 200px;
}
.koudian {
    font-size: 14px;
    padding: 10px;
    padding-left: 0;
    display: flex;
    color: #022955 !important;
}
/* // 更多精准推送 */
.detail_cont-one {
    font-size: 14px;
    margin-bottom: 20px;
    color: #022955;
}
.detail_cont-one h1 {
    font-size: 14px;
    line-height: 45px;
}
.detail_cont-one .el-checkbox {
    width: 215px;
    line-height: 30px;
}
.detail_cont-one .el-radio__inner {
    border: 1px solid #dcdfe6;
    border-radius: 100%;
    width: 14px;
    height: 14px;
    background-color: #fff;
    cursor: pointer;
    box-sizing: border-box;
}

.detail_cont-one /deep/ .el-radio__inner {
    width: 16px;
    height: 16px;
    border-color: #409eff;
}
.detail_cont-one /deep/ .el-radio__inner::after {
    width: 12px;
    height: 12px;
}
.detail_cont-one /deep/ .el-radio__input.is-checked .el-radio__inner {
    /* // border-color: #1290C9; */
    border-color: #409eff !important;
    background: #fff;
}
.detail_cont-one /deep/ .el-radio__input.is-checked .el-radio__inner::after {
    background: #409eff;
}
.detail_cont-one /deep/ .el-radio__input.is-checked + .el-radio__label {
    /* // color: #1290C9; */
    font-size: 14px;
    color: #409eff !important;
}
.detail_cont-one /deep/ .el-radio__input.is-checked .el-radio__inner::after {
    color: #409eff !important;
}
.form-email {
    display: flex;
    width: 100%;
}
.block-con-body-text {
    word-break: keep-all;
    text-align: justify;
    font-size: 14px;
    color: #022955;
}
.das_top_cont {
    /* margin-top: 50px; */
}

.das_top_item .item_img {
    width: 40px;
    height: 40px;
    line-height: 48px;
    text-align: center;
    border-radius: 50%;
}
.das_top_item .color1 {
    background: #1290c9;
}
.das_top_item .color2 {
    background: #f56b6b;
}
.das_top_item .color3 {
    background: #55b419;
}
.das_top_item .color4 {
    background: #ff7600;
}
.das_top_item .item_img img {
    width: 18px;
    height: 18px;
}
.das_top_item .item_title {
    font-size: 18px;
    color: #022955;
    margin: 10px 0 10px;
    text-align: left;
}
.das_top_item .item_group {
    margin-left: 20px;
    font-size: 14px;
    width: 240px;
}
.das_top_item .item_lab {
    display: block;
    color: #8497ab;
    /* margin-bottom: 8px; */
    /* width: 180px; */
    margin-top: 5px;
}
.item_txt {
    font-size: 18px;
}
@media (max-width: 821px) {
    .tab_box /deep/ .el-tabs__nav-wrap {
        width: 100% !important;
        height: auto !important;
    }
    .tab_box /deep/ .el-tabs__nav {
        white-space: pre-wrap;
    }
    .tab_box /deep/ .el-tabs__header {
        padding: 11px 5px;
    }
    .tab_box /deep/ .el-tabs__item {
        height: 39px !important;
        font-size: 12px !important;
            padding: 0px 10px !important;
    }
    .tab_box /deep/ .el-tabs__item.is-active {
        width: 140px !important;
        padding: 0px 10px !important;
        font-size: 12px !important;
    }
    .his_sel_box {
        flex-wrap: wrap;
    }
    .sel_box {
        margin: 10px 0px !important;
    }
    .his_sel_box .sel_box /deep/ .el-input__inner {
        width: 224px !important;
    }
    .history_box .phoen_center_flex_warp {
        flex-wrap: wrap;
        /* justify-content: end !important; */
    }
    .das_top_item {
        margin: 10px 0px !important;
        padding: 15px !important;
    }
    .das_top_item .item_group {
        width: 200px !important;
    }
    .phone_table_box_item {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        padding: 20px 0;
        border-bottom: 1px solid #e0dce5;
    }

    .phone_table_box_item_right {
        font-size: 14px;
        width: 99%;
    }
    .phone_table_box_item_right h1 {
        font-size: 14px;
        font-weight: normal;
        height: 28px !important;
    }
    .phone_table_box_item_right h2 {
        font-size: 12px;
        color: #656373;
        font-weight: normal;
        line-height: 22px;
    }
    .line_1 {
        white-space: nowrap !important;
    }

    .oper_down img {
        width: 18px !important;
        height: 16px !important;
    }
    .koudian{
        flex-wrap: wrap;
    }
    .koudian-title{
        width: 100% !important;
        margin-bottom: 10px ;
    }
    .el-form-item{
        flex-wrap: wrap;
    }
    .el-form-item__label{
        text-align: left !important;
    }

    .report_form  .el-input__inner  {
        width: 300px !important;
    }
    .item_txt{
        font-size: 14px !important;
    }
    .das_top_item .item_lab {
        font-size: 12px !important;
    }
    .btn_blue {
    width: 120px;
    height: 40px;
   
    font-size: 13px;
 
}
}
</style>