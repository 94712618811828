<template>
    <div>


        <!-- 点击 Update data 后弹出 -->
        <el-dialog :visible.sync="newDialog" center :close-on-click-modal="false" custom-class="dialog_lg"  @close="closeDialog" :append-to-body="true">
            <div class="detail_cont">
                <div class="fin_title">Financial</div>
                <el-form ref="form" :model="form" label-width="184px" class="form_box" label-position="left" disabled>
                    <div class="flex_boxtwo flex_box">
                        <el-form-item label="Please provide more info:" class="fin_top_item">
                            <el-select v-model="form.pleaseProvideMoreInfo" placeholder="Select" class="fin_top_sel">
                                <el-option :label="item" :value="item" v-for="(item, index) in provideOptions" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Year:" class="fin_top_year">
                            <el-select v-model="form.year" placeholder="Select" class="fin_top_sel">
                                <el-option :label="item" :value="item" v-for="(item, index) in yearOptions" :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="fin_cont_box flex_center_between_box phoen_center_flex_warp">
                        <div class="fin_modu_box">
                            <div class="fin_modu">
                                <el-form-item label="Total Current Assets:">
                                    <el-input v-model="form.totalCurrentAssets"></el-input>
                                </el-form-item>
                                <el-form-item label="Cash & Bank:">
                                    <el-input v-model="form.cashBank"></el-input>
                                </el-form-item>
                                <el-form-item label="Account receivable:">
                                    <el-input v-model="form.accountReceivable"></el-input>
                                </el-form-item>
                                <el-form-item label="Advances to Suppliers:">
                                    <el-input v-model="form.advancesToSuppliers"></el-input>
                                </el-form-item>
                                <el-form-item label="Inventory:">
                                    <el-input v-model="form.inventory"></el-input>
                                </el-form-item>
                                <el-form-item label="To be appointed expense:">
                                    <el-input v-model="form.toBeAppointedExpense"></el-input>
                                </el-form-item>
                            </div>
                            <div class="fin_modu">
                                <el-form-item label="Total non-current assets:">
                                    <el-input v-model="form.totalNonCurrentAssets"></el-input>
                                </el-form-item>
                                <el-form-item label="Long-term investment:">
                                    <el-input v-model="form.longTermInvestment"></el-input>
                                </el-form-item>
                                <el-form-item label="Fixed assets net value:">
                                    <el-input v-model="form.fixedAssetsNetValue"></el-input>
                                </el-form-item>
                                <el-form-item label="Projects under construction:">
                                    <el-input v-model="form.projectsUnderConstruction"></el-input>
                                </el-form-item>
                                <el-form-item label="Long-term to be appointed expenise:">
                                    <el-input v-model="form.longTermToBeAppointedExpenise"></el-input>
                                </el-form-item>
                                <el-form-item label="Deferred tax debit:">
                                    <el-input v-model="form.deferredTaxDebit"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="fin_modu_box">
                            <div class="fin_modu">
                                <el-form-item label="Total current liabilities:">
                                    <el-input v-model="form.liabilities"></el-input>
                                </el-form-item>
                                <el-form-item label="Account payable:">
                                    <el-input v-model="form.accountPayable"></el-input>
                                </el-form-item>
                                <el-form-item label="Advances from clients:">
                                    <el-input v-model="form.advancesFromClients"></el-input>
                                </el-form-item>
                                <el-form-item label="Salaries payable:">
                                    <el-input v-model="form.salariesPayable"></el-input>
                                </el-form-item>
                                <el-form-item label="Taxes payable:">
                                    <el-input v-model="form.taxesPayable"></el-input>
                                </el-form-item>
                                <el-form-item label="Other accounts payable:">
                                    <el-input v-model="form.otherAccountsPayable"></el-input>
                                </el-form-item>
                            </div>
                            <div class="fin_modu">
                                <el-form-item label="Total equity:">
                                    <el-input v-model="form.totalEquity"></el-input>
                                </el-form-item>
                                <el-form-item label="Paid up capital:">
                                    <el-input v-model="form.paidUpCapital"></el-input>
                                </el-form-item>
                                <el-form-item label="Capital reserves:">
                                    <el-input v-model="form.capitalReserves"></el-input>
                                </el-form-item>
                                <el-form-item label="Surplus reserves:">
                                    <el-input v-model="form.surplusReserves"></el-input>
                                </el-form-item>
                                <el-form-item label="Undistrbuted porfit:">
                                    <el-input v-model="form.undistrbutedPorfit"></el-input>
                                </el-form-item>
                                <el-form-item label="Total assets:">
                                    <el-input v-model="form.totalAssets"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="fin_modu_box">
                            <div class="fin_modu last">
                                <el-form-item label="Turnover:">
                                    <el-input v-model="form.turnover"></el-input>
                                </el-form-item>
                                <el-form-item label="Cost of goods sold:">
                                    <el-input v-model="form.costOfGoodsSold"></el-input>
                                </el-form-item>
                                <el-form-item label="Sales tax:">
                                    <el-input v-model="form.salesTax"></el-input>
                                </el-form-item>
                                <el-form-item label="Gross profit:">
                                    <el-input v-model="form.grossProfit"></el-input>
                                </el-form-item>
                                <el-form-item label="Other operating profils:">
                                    <el-input v-model="form.otherOperatingProfils"></el-input>
                                </el-form-item>
                                <el-form-item label="Sales expense:">
                                    <el-input v-model="form.salesExpense"></el-input>
                                </el-form-item>
                                <el-form-item label="Management expense:">
                                    <el-input v-model="form.managementExpense"></el-input>
                                </el-form-item>
                                <el-form-item label="Finance expense:">
                                    <el-input v-model="form.financeExpense"></el-input>
                                </el-form-item>
                                <el-form-item label="Operating profit:">
                                    <el-input v-model="form.operatingProfit"></el-input>
                                </el-form-item>
                                <el-form-item label="Non-operating income:">
                                    <el-input v-model="form.nonOperatingIncome"></el-input>
                                </el-form-item>
                                <el-form-item label="Profit before tax:">
                                    <el-input v-model="form.profitBeforeTax"></el-input>
                                </el-form-item>
                                <el-form-item label="Income tax:">
                                    <el-input v-model="form.incomeTax"></el-input>
                                </el-form-item>
                                <el-form-item label="Net income:">
                                    <el-input v-model="form.netIncome"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="fin_title">Business</div>
                    <div class="bus_box">
                        <el-form-item label="No.of employees:">
                            <el-input v-model="form.noOfEmployees"></el-input>
                        </el-form-item>
                        <el-form-item label="Core management:">
                            <el-input v-model="form.coreManagement"></el-input>
                        </el-form-item>
                        <el-form-item label="Core business activites:">
                            <el-input v-model="form.coreBusinessActivites" type="textarea" :rows="5"></el-input>
                        </el-form-item>
                    </div>
                    <div class="bus_box2">
                        <el-form-item label="Int' Business ">
                            <el-select v-model="form.intBusiness" placeholder="Select">
                                <el-option :label="item" :value="item" v-for="(item, index) in intBusOptions" :key="index"></el-option>
                            </el-select>
                            <el-input class="m_l" v-model="form.intBusiness1" placeholder="Please describe your activites"></el-input>
                            <el-input class="m_l" v-model="form.activites2" placeholder="Please describe your activites"></el-input>
                            <el-input class="m_l" v-model="form.intBusiness3" placeholder="Please list countries you do busine"></el-input>
                        </el-form-item>
                        <el-form-item label="Core competency :">
                            <el-checkbox-group v-model="form.coreCompetency" class="check_box">
                                <el-checkbox v-for="competency in CompetencyList" :label="competency" :key="competency">{{ competency }}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </div>
                    <el-form-item label="Business outlook:" class="out_item">
                        <el-select v-model="form.businessOutlook" placeholder="Select">
                            <el-option :label="item" :value="item" v-for="(item, index) in busOptions" :key="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Upload files:"  class="out_item">
                        <el-upload class="upload-demo" action="fakeaction" ref="uploadFile"    v-model="form.uploadFiles" :file-list="filesList">
                            <el-button slot="trigger">Upload</el-button>
                        </el-upload>
                    </el-form-item>
                    <div class="btn_box">
                        <!-- <el-button class="btn_export" @click="onSubmit()">Submit</el-button> -->
                       
                    </div>
                </el-form>
                 <!-- <el-button class="btn_cancel" @click="cancel()">Cancel</el-button> -->
            </div>
        </el-dialog>

  
    </div>
</template>
<script>

export default {
    props: {
        showReport: {
            type: Boolean,
            default: false,
        },
        powerStatus: {
            type: Boolean,
            default: false,
        },
        companynameen: {
            type: String,
            default: '',
        },
        saveform: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            // newDialog: true,
            tabName: 'Reportmycompany',
            finDialog: false,
            reportDialog: false,
            newDialogtwo: false,
            form: {
                pleaseProvideMoreInfo: '',
                year: new Date().getFullYear(),
                totalCurrentAssets: '',
                cashBank: '',
                accountReceivable: '',
                advancesToSuppliers: '',
                inventory: '',
                toBeAppointedExpense: '',
                totalNonCurrentAssets: '',
                longTermInvestment: '',
                fixedAssetsNetValue: '',
                projectsUnderConstruction: '',
                longTermToBeAppointedExpenise: '',
                deferredTaxDebit: '',
                liabilities: '',
                accountPayable: '',
                advancesFromClients: '',
                salariesPayable: '',
                taxesPayable: '',
                otherAccountsPayable: '',

                totalEquity: '',
                paidUpCapital: '',
                capitalReserves: '',
                surplusReserves: '',
                undistrbutedPorfit: '',
                totalAssets: '',
                turnover: '',
                costOfGoodsSold: '',
                salesTax: '',
                grossProfit: '',
                otherOperatingProfils: '',
                salesExpense: '',
                managementExpense: '',
                financeExpense: '',
                operatingProfit: '',
                nonOperatingIncome: '',
                profitBeforeTax: '',
                incomeTax: '',
                netIncome: '',
                noOfEmployees: '',
                coreManagement: '',
                coreBusinessActivites: '',
                intBusiness3: '',
                intBusiness1: '',
                intBusiness2: '',
                intBusiness: '',
                coreCompetency: [],
                businessOutlook: '',
                uploadFiles: [],
            },
            type: '701',
            filesList: [],
            provideOptions: ['CNY', 'EURO', 'USD'],
            yearOptions: [], //['2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011', '2010', '2009', '2008'],
            busOptions: ['Good', 'Not optimistic', 'Unsure'],
            CompetencyList: ['Patent', 'Trademark', 'Copyright', 'Software copyright', 'High-tech'],
            intBusOptions: ['Yes', 'No'],
            subjectOptions: ['COMPANY SNAPSHOT','COMPANY PROFILE','MANAGEMENT', 'OWNERSHIP', 'BUSINESS OPERATIONS', 'PERFORMANCE','IMPORT&EXPORT'],
            disabledReport1: true,
            disabledReport2: true,
            disabledReport: true,
            reportForm: { subject: '', demand: '' },
            addReportList: [{ subject: '', demand: '' }],
            checked: false,
            window:window
        };
    },
    computed: {
        newDialog: {
            get() {
                return this.showReport;
            },
            set(newValue) {},
        },
        companyName: {
            get() {
                return this.companynameen;
            },
            set(newValue) {},
        },
    },
  mounted() {
  
  },
  watch:{
    saveform: {
            handler(newVal, oldVal) {
                this.filesList=[]
                this.form=newVal
                let that= this
                this.form.uploadFiles= this.form.uploadFiles==undefined?[]: this.form.uploadFiles
                this.form.uploadFiles.map(item=>{
                    that.filesList.push({ name: item, url: item })
                })
              
  
                // this.loadig=false
            },
            immediate: true,
        },
  },
  
methods: {
   //关闭弹窗
   closeDialog() {
    console.log(this.newDialog);
      this.$emit('closeReportDialog', !this.newDialog)
    },  
    // cancel(){
    //     this.showReport=false
    //     // this.$emit('closeReportDialog', !this.newDialog)
    // }
},
};
</script>
<style scoped>
.tab_box /deep/ .el-tabs__header {
    margin-bottom: 10px;
}
.el-tabs--card > .el-tabs__header {
    border: none;
}
.tab_box /deep/ .el-tabs__header {
    width: 100%;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}
.tab_box /deep/ .el-tabs__header {
    padding: 11px 16px;
    display: none;
}
.result_tab {
    padding: 20px;
}
.tab_box /deep/ .el-tabs__nav-wrap {
    width: 100%;
    height: 28px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.tab_box /deep/ .el-tabs__nav {
    width: 100%;
    border: none;
    padding: 2px 28px;
    display: flex;
    justify-content: space-between;
}
.tab_box /deep/ .el-tabs__item {
    padding: 0 15px;
    width: 160px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #8497ab;
    border: none;
    text-align: center;
}
.tab_box /deep/ .el-tabs__item:last-child {
    padding-right: 15px !important;
}
.tab_box /deep/ .el-tabs__item.is-active {
    background: #ff7600;
    box-shadow: 0px 1px 2px 0px #c44306;
    border-radius: 4px;
    font-family: 'Arial Bold';
    color: #ffffff;
}

.tab_box /deep/.el-tabs__content {
    width: 440px;
    height: 130px;
    padding: 10px 0;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.cont_item {
    position: relative;
    width: 100%;
    height: 50px;
    padding: 12px 20px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 26px;
    background: #f2f5f9;
    background-image: url(../assets/img/detail/btn_ic9.png);
    background-repeat: no-repeat;
    background-size: 8px 14px;
    background-position: 95% center;
    cursor: pointer;
}

.fin_title {
    font-family: 'Arial Bold';
    margin-bottom: 20px;
    line-height: 24px;
    font-size: 20px;
    color: #022955;
}
.form_box /deep/ .el-input__inner {
    width: 162px;
    height: 34px;
    line-height: 34px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.fin_top_sel /deep/ .el-input__inner {
    width: 276px;
    height: 34px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    font-size: 12px;
}
.fin_top_year /deep/.el-form-item__label,
.fin_top_item /deep/.el-form-item__label {
    width: auto !important;
}
.form_box /deep/.el-form-item__label {
    padding-right: 10px;
}
.fin_top_item /deep/ .el-form-item__content {
    margin-left: 174px !important;
}
.fin_top_year /deep/ .el-form-item__content {
    margin-left: 50px !important;
}
.fin_top_year {
    margin-left: 40px;
}
.form_box /deep/ .el-form-item__label {
    color: #022955 !important;
}
.form_box /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab !important;
}
.fin_cont_box {
    margin-bottom: 10px;
}
.fin_modu {
    width: 355px;
    height: 320px;
    padding: 20px 5px;
    margin-bottom: 20px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.fin_modu.last {
    height: 661px;
}
.form_box /deep/ .el-form-item__content {
    line-height: 34px;
}
.form_box /deep/ .el-form-item__label {
    display: flex;
    align-items: center;
    height: 37px;
    line-height: 16px;
}

.form_box /deep/ .el-form-item {
    margin-bottom: 12px;
}
.bus_box /deep/ .el-textarea__inner,
.bus_box /deep/ .el-input__inner {
   width: 900px;
    background: #f2f5f9;
}
.bus_box2 /deep/ .el-form-item__content {
    display: flex;
}
.out_item /deep/ .el-input__inner,
.bus_box2 /deep/ .el-input__inner {
    width: 214px;
    height: 34px;
    background: #f2f5f9;
}
.bus_box2 /deep/ .m_l .el-input__inner {
    margin-left: 16px;
}
.form_box /deep/ .out_item {
    margin-bottom: 20px;
}
.form_box /deep/ .el-input__icon {
    height: auto;
}
.upload-demo /deep/ .el-button {
    width: 90px;
    height: 34px;
    padding: 8px 20px;
    background: #edf9ff;
    border-radius: 12px;
    border: 1px solid #1290c9;
    color: #1290c9;
    font-size: 14px;
}
.check_box /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid #8497ab;
}
.report_form /deep/ .el-form-item__label,
.check_box /deep/ .el-checkbox__label {
    font-size: 14px;
    color: #022955;
}
.check_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.check_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #1290c9;
    border-color: #1290c9;
}
.check_box /deep/ .el-checkbox__inner::after {
    height: 12px;
    left: 5px;
    top: 0px;
    width: 6px;
}
.btn_box {
    text-align: right;
    margin-top: 24px;
    margin-bottom: 16px;
}
.btn_box button + button {
    margin-left: 40px;
}
.rep_list {
    padding-bottom: 10px;
}

.add_btn {
    background: #1290c9;
    background-image: url(../assets/img/detail/add_ic.png);
}

.del_btn {
    background: #f56b6b;
    background-image: url(../assets/img/detail/del_ic.png);
}
.del_btn,
.add_btn {
    float: right;
    width: 26px;
    height: 26px;
    margin-top: -32px;
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
    border-radius: 50%;
}
.report_form_box {
    padding: 10px 10px 0;
    margin-top: 42px;
    margin-bottom: 20px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.report_form /deep/ .el-input__inner {
    width: 420px;
    height: 34px;
    line-height: 34px;
}
.report_form /deep/ .el-form-item {
    margin-bottom: 20px;
}
.limit_box /deep/ .el-textarea__inner {
    height: 150px;
}
.report_item {
    margin-top: 48px;
}

.dialog_lg.el-dialog {
    width: 1150px;
    /* max-height: 85vh; */
    background: #ffffff;
    border-radius: 20px;
}
.detail_cont .el-upload-list__item {
    display: flex;
}
.el-upload-list__item-status-label {
    display: inline-block;
}
.el-upload-list__item:hover .el-upload-list__item-status-label {
    display: none;
}
.detail_cont .el-upload-list__item-status-label,
.detail_cont .el-upload-list__item .el-icon-close {
    position: static;
}
.detail_cont .el-upload-list__item .el-icon-close {
    line-height: 26px;
}
.detail_cont_text {
    /* 解决英文截断问题 */
    word-break: keep-all;
    text-align: justify;
    font-size: 14px;
    color: #022955;
    line-height: 25px;
}
.detail_cont_text p {
    padding-left: 10px;
    background: #f2f5f9;
    margin: 5px 0px;
}
.dialog_detail_check .el-checkbox .el-checkbox__label {
    width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    top: 5px;
}
@media (max-width: 821px) {
  .report_form /deep/ .el-input__inner {
    width: 300px !important;
}
.phoen_center_flex_warp{
    flex-wrap: wrap;
}
.flex_boxtwo {
    flex-wrap: wrap;
}
.fin_modu {
    width: 100% !important;
}
.form_box /deep/ .el-form-item {
    margin-bottom: 12px;
}

.bus_box /deep/ .el-input__inner {
    width: 100% !important;
    background: #f2f5f9;
}
.bus_box2 /deep/ .el-form-item__content {
    display: flex;
    margin-left: 0px !important;
        flex-wrap: wrap;
       float: left;
}
.bus_box /deep/ .el-form-item__content{
    margin-left: 0px !important;
}
.bus_box /deep/ .el-textarea__inner {
    width: 100% !important;
}
.bus_box2 /deep/ .m_l .el-input__inner{
    margin-left: 0px !important;
}
.m_l {
    margin: 10px 0px !important;
}
.fin_top_year {
    margin-left: 0px !important;
}
.out_item /deep/ .el-form-item__content ,.fin_top_year /deep/ .el-form-item__content ,.fin_top_item /deep/ .el-form-item__content {
       margin-left: 0px !important;
}
.fin_top_year /deep/ .el-form-el-form-item__label {
    width: 180px !important;
}
}
</style>
